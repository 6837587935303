import React from "react";
import "./App.scss"
import { ThemeProvider } from "react-bootstrap";
import "./network/firebaseConfig"
import ApplicateRoute from "./pages";

function App() {

  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
    >
      <ApplicateRoute />
    </ThemeProvider>
  );
}

export default App;