import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Home from "./home"
import FlashpointHomePage from "./flashpoint";
import FlashpointOptions from "./flashpoint/options";
import OptionScore from "./flashpoint/optionScore";
import OptionReasons from "./flashpoint/optionReasons";
// import Solutions from "./flashpoint/solutions";
import Points from "./flashpoint/points";
import Login from "./auth/login";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { getAuthValue } from "../network/authAction";
import { flashpointUserInteraction } from "../network/flashpointAction";
import ShareExperience from "./lab/share";
import LabExperiment from "./lab/experiments";

const PageContainer = ({ children }) => {
    const routePath = useLocation();

    const onTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        onTop()

        flashpointUserInteraction(routePath.pathname)
    }, [routePath]);

    return children
}

const ApplicateRoute = () => {
    const privatePageList = [
        {
            pageLink: "/:flashpointId/options/:optionId/reason",
            view: OptionReasons,
            isProtected: true
        },
        {
            pageLink: "/:flashpointId/options/:optionId",
            view: OptionScore,
            isProtected: true
        },
        {
            pageLink: "/:flashpointId/points",
            view: Points,
            isProtected: true
        },
        {
            pageLink: "/:flashpointId/options",
            view: FlashpointOptions,
            isProtected: true
        },
        {
            pageLink: "/:flashpointId",
            view: FlashpointHomePage,
            isProtected: true
        },
        // {
        //     pageLink: "/admin",
        //     view: AdminRoute,
        //     isProtected: true
        // },
        {
            pageLink: "lab/experiments/:unitId",
            view: LabExperiment,
            isProtected: true
        },
        {
            pageLink: "/lab/share/:unitId",
            view: ShareExperience,
            isProtected: true
        },
        {
            pageLink: "/",
            view: Home,
            isProtected: true
        }
    ];

    const auth = getAuthValue()

    const [user, setUser] = useState(undefined)

    const storageUser = localStorage.getItem("user")

    useEffect(() => {
        if (storageUser !== null) {
            setUser(localStorage.getItem("user"))
        }
    }, [storageUser])

    onAuthStateChanged(auth, user => {
        if (user) {
            localStorage.setItem("user", JSON.stringify(user))
            setUser(user)
        }
        else {
            localStorage.removeItem("user", JSON.stringify(user))
            setUser(null)
        }
    })

    return <Router>
        <Routes>
            {user ? privatePageList.map(page => <Route
                path={page.pageLink}
                element={<PageContainer>
                    <page.view />
                </PageContainer>}
                key={page.pageLink} />
            ) : <Route path="*" element={<Login />} />}
        </Routes>
    </Router>
}

export default ApplicateRoute