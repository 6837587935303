// import illumineLogo from "../../assets/illumine-logo.svg"
import growOn1 from "../../assets/mindgog-home-logo.svg"

export const SplashScreen = ({ text, hidden }) => {
    return <div style={{ width: '100vw', height: '100vh', display: hidden ? "none" : 'flex' }} className="m-auto">
        <img src={growOn1} style={{ width: "50%", paddingBottom: "40%" }} alt="program-logo" className="m-auto" />
        {/* <img src={growOn1} style={{ width: '60%', marginLeft: "auto", marginRight: "auto", }} alt="growon-icon" /> */}
        {/* <div className="fixed-bottom m-auto d-flex row w-fit" style={{ flexDirection: "column" }}>
            <img src={illumineLogo} style={{ width: "75px" }} className="m-auto" alt="illumine-logo"/>
            <span className="w-fit" style={{ textTransform: "uppercase" }}>an illumine product</span>
        </div> */}

        <div className="fixed-bottom" style={{ marginBottom: "10%", textAlign: "center" }} >
            <div className="spinner-border text-primary " role="status" />
            <div>Loading {text}</div>
        </div>
    </div>
}