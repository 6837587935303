import { useParams } from "react-router-dom"
// import { flashpoints } from "../../assets/flashpoint"
import { LinkButton } from "../../components/LinkButton"
import { mdiArrowDown, mdiCheckCircle } from '@mdi/js';
import Icon from '@mdi/react'
import { TopBar } from "../../components/TopBar"
import { useState } from "react";
import { getFlashpointById } from "../../network/flashpointAction";
import { SplashScreen } from "../SplashScreen";

const OptionReasons = () => {
    const { flashpointId, optionId } = useParams()

    const flashpoint = getFlashpointById(flashpointId)

    const option = flashpoint?.options.filter(opt => opt.opt_id === optionId)[0]

    const otherOptions = flashpoint?.options.filter(opt => opt.opt_id !== optionId)

    const [loading, setLoading] = useState({})

    const allLoaded = Object.keys(loading).length === otherOptions?.length + (option ? 1 : 0)

    // console.log(allLoaded, Object.keys(loading).length, otherOptions, option, otherOptions?.length + (option ? 1 : 0))

    return <>
        {!allLoaded && <SplashScreen text={"Responses"} />}

        <div style={{ backgroundColor: flashpoint?.color }}>

            <TopBar title={flashpoint?.topBarText} hidden={!allLoaded} />
            <div style={{ backgroundColor: flashpoint?.color, marginTop: "10%", display: !allLoaded ? "none" : "block" }} className="flashpoint" >
                {option && <div style={{ borderRadius: "20px", padding: "2%" }} className="text-center">
                    {/* <span className="fw-bold font-26 line-32">You chose this<br/> 5 point strategy</span> */}
                    <div className="flashpoint-title" style={{ paddingRight: "0" }}>You chose a<br /> {option?.opt_score}-point strategy</div>
                    {/* <div className="narrative my-3">{option?.opt_text}</div> */}
                    <div className="narrative my-3">Here's how it may play out...</div>

                    <img src={option?.opt_reason} className="w-100" alt="Choose Reason" onLoad={() => setLoading(oldValue => ({ ...oldValue, [option?.opt_id]: true }))} />

                    {!loading?.[option?.opt_id] && <div className="spinner-border text-primary m-auto" role="status" />}
                </div>}

                <div className="flashpoint-title" style={{
                    marginTop: option ? '18%' : '', textAlign: "center", paddingRight: "0",
                    // color: "#767676", 
                    fontSize: "22px", marginBottom: "-4%"
                }}>
                    CONSIDER THESE <br /> OTHER STRATEGIES
                    <br />
                    <Icon path={mdiArrowDown} size={2} />
                </div>

                {otherOptions?.map((opt) =>
                    <div style={{ textAlign: "center", marginTop: '16%', }} key={opt.opt_id}>
                        <div className="flashpoint-title" style={{
                            margin: '8% 0 0 0',
                            paddingRight: "0"
                        }}>{opt.opt_score}-point strategy</div>
                        {/* <div className="narrative my-3">{opt?.opt_text}</div> */}
                        <div className="narrative mb-3 mt-1">Here's how it may play out...</div>
                        <img src={opt.opt_reason} className="w-100" key={opt.opt_id} alt="Other Reason" onLoad={() => setLoading(oldValue => ({ ...oldValue, [opt.opt_id]: true }))} />
                        {!loading?.[opt.opt_id] && <div className="spinner-border text-primary m-auto" role="status" />}
                    </div>)}
                {/* <div className="d-flex"> */}
                <LinkButton to={`/`}
                    buttonText="Done"
                    endButtonIcon={<Icon path={mdiCheckCircle}
                        size={1.5}
                        color="white"
                    />}
                    buttonStyle={{ color: "white", backgroundColor: "black", marginTop: "16%", opacity: "0.88" }}
                />
            </div>
        </div>
    </>
}

export default OptionReasons
