import { Link } from "react-router-dom"
import PropTypes from 'prop-types';

export const LinkButton = ({ to, buttonText, buttonStyle, endButtonIcon, disabled, buttonClasses }) => {
    return <Link className={`btn ${buttonClasses ? buttonClasses : ''}`} style={{ boxShadow: disabled ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.25)', ...buttonStyle, pointerEvents: disabled ? "none" : null }} to={to}>
        <span style={{ marginRight: "5%" }}> {buttonText} </span>
        <span> {endButtonIcon} </span>
    </Link >
}

Link.propTypes = {
    to: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    buttonStyle: PropTypes.object,
    endButtonIcon: PropTypes.element,
    disabled: PropTypes.bool
}