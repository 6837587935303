import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDFfQHe0J6blvAp2KsSfuesSKDPmVvORco",
  authDomain: "human-possibility.firebaseapp.com",
  projectId: "human-possibility",
  storageBucket: "human-possibility.appspot.com",
  messagingSenderId: "234260719505",
  appId: "1:234260719505:web:fb5a39d56a3bf475895c30",
  measurementId: "G-56NTE2GS2K"
};

export const app = initializeApp(firebaseConfig);