import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { saveDocument } from "./firestoreAction"

const auth = getAuth();

const provider = new GoogleAuthProvider();

export const loginWithGoogle = () => {
    signInWithPopup(auth, provider)
        .then((result) => {
            const user = result.user;
            const userDoc = user.toJSON()
            for (const key in userDoc) {
                if (userDoc[key] === undefined) {
                    delete userDoc[key];
                }
            }
            saveDocument("users", user.uid, userDoc)
            localStorage.setItem("user", JSON.stringify(user))
        }).catch((error) => {
            // console.log(error)
        });
}

export const getAuthValue = () => {
    return auth
}

export const getCurrentUser = () => {
    return auth.currentUser
}

export const logout = () => {
    auth.signOut()
    localStorage.removeItem("user")
    return auth
}