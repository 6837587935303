import moment from "moment";
import React, { useEffect, useState } from "react";
import { FlashpointCard } from "../../components/FlashpointCard";
import { getTodaysFlashpoints } from "../../network/flashpointAction";

const HomeFlashpoint = ({ completed, loading, setLoading }) => {
    const [todayFlashpoint, setTodayFlashpoint] = useState()
    const [duration, setDuration] = useState(undefined)
    const currentTime = moment()

    useEffect(() => {
        (async () => {
            const flash = await getTodaysFlashpoints()
            setTodayFlashpoint(flash)
            setLoading(Object.keys(flash).length)
        })()
    }, [])

    useEffect(() => {
        let startDate = moment().utc();
        if (todayFlashpoint) {
            let timeEnd = moment(Object.values(todayFlashpoint)?.[0].nextFlashpoint.toDate()).utc()
            let diff = timeEnd.diff(startDate);
            setDuration(moment.duration(diff))
        }
    }, [currentTime])

    const addZeroBefore = (n) => {
        return (n < 10 ? '0' : '') + n;
    }

    return <> <div style={{ display: !completed ? "none" : 'block' }}>

        {/* <div className={`feed-tile mt-3 ${completed ? '' : "mb-3"}`} style={{ paddingLeft: "1.5rem" }}>
            {completed ? "Next Flashpoint" : "Flashpoint of the Day"}
        </div> */}

        {/* {completed && <span className="feed-tile mb-3" style={{ paddingLeft: "1.5rem", color: "black" }}>
            {duration && `${addZeroBefore(duration?.hours() + duration?.days() * 24)}:${addZeroBefore(duration?.minutes())}:${addZeroBefore(duration?.seconds())}`}
        </span>} */}

    </div>

        {
            todayFlashpoint && Object.values(todayFlashpoint).map((flash) => <FlashpointCard
                key={flash?._id}
                cardId={flash?._id}
                title={flash?.title}
                cardImage={flash?.cardImg || ''}
                cardButtonText={flash?.cardButtonText1 || ''}
                redirectLink={`${flash?._id}`}
                cardColor={flash?.color || ''}
                completed={flash?.isCompletedFlashpoint}
                setLoading={setLoading}
                loading={false}
                onLoad={() => { setLoading((oldValue => oldValue - 1)) }}
            />
            )
        }
    </>
}

export default HomeFlashpoint