import { Link } from "react-router-dom"

const Points = () => {
    const points = {
        "Unit Completion": 2, "Strategy": 5, "Sharing Bonus": 10
    }

    return <div>
        <span>Congratulations! You have earned</span>

        <div className="border rounded-circle m-auto" style={{ width: "100px", height: "100px", textAlign: "center", lineHeight: "100px", backgroundColor: "#FBD837" }}>
            {Object.values(points).reduce((prev, curr) => prev + curr, 0)}
        </div>
        {Object.entries(points).map(point => <div className={point[0]}>{point[0]}: {point[1]}</div>)}

        <div className="d-flex">
            <Link className="btn m-auto" style={{
                // position: "absolute", bottom: "10%", left: "40%", 
                background: "#FF5C5C", color: "white"
            }} to={`/`}>
                Go to Home
            </Link>
        </div>        
    </div>
}

export default Points