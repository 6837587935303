import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import bulb from "../../assets/bulb-single.svg";
import { LinkButton } from "../../components/LinkButton"
import { mdiArrowRight } from '@mdi/js';
import Icon from '@mdi/react'
import { TopBar } from "../../components/TopBar"
import { getFlashpointById } from "../../network/flashpointAction";
import { SplashScreen } from "../SplashScreen";
var _ = require('lodash');

const FlashpointOptions = () => {
    const { flashpointId } = useParams()

    const flashpoint = getFlashpointById(flashpointId)
    const [options, setOptions] = useState()
    const [loading, setLoading] = useState()

    useEffect(() => {
        const opt = flashpoint?.options
        setOptions(_.shuffle(opt))
        setLoading(opt.filter((o) => o.type == "img").length)
        // eslint-disable-next-line
    }, [])
    // const flashpoint = flashpoints.filter((flash) => flash._id === flashpointId)[0]

    const [option, changeOption] = useState(undefined)

    return <div style={{
        backgroundColor: loading != 0 ? "" : flashpoint?.color,
        // height: "100vh", 
        minHeight: "100vh",
        height: "100%",
    }}>
        {loading != 0 && <SplashScreen text={"Options"} />}
        <TopBar title={flashpoint.topBarText} hidden={loading != 0} />
        <div className="flashpoint" hidden={loading != 0}>
            <div className="flashpoint-title mt-3 mb-3 d-flex" style={{ alignItems: "end", justifyContent: "space-between", paddingRight: "0" }}>
                <span className="">{flashpoint.optionQuestion2}</span>
                <img src={bulb} alt="bulb" />
            </div>
            {options?.map(opt => (
                <div className="option" style={{
                    borderColor: option === opt.opt_id ? 'black' : '',
                    borderWidth: option === opt.opt_id ? '3px' : '',
                    marginTop: "10px"
                }} key={opt.opt_id}
                    onClick={() => { changeOption(opt.opt_id) }}
                >
                    <input
                        type="radio"
                        name='options'
                        id={opt.opt_id}
                        onChange={() => { }}
                        style={{
                            // width: '20px', height: '40px', 
                            cursor: 'pointer',
                            // width: "20%",
                            // height: "20%",
                            alignSelf: "center",
                            // height: "2rem", width: "25%"
                            color: "black",
                            background: "black",
                            backgroundColor: "black",
                            // border: "2px solid white",
                            fill: "black"
                        }}
                        checked={opt.opt_id == option}
                        value={opt.opt_id}
                    />
                    <div>
                        <label htmlFor={opt.opt_id}>
                            {opt.opt_text}
                        </label>
                        {opt?.type == "img" && <img src={opt.opt_img} htmlFor={opt.opt_id} onLoad={() => { setLoading(oldValue => oldValue - 1) }} />}
                    </div>
                </div>
            ))}
            <LinkButton
                to={`${option}`}
                buttonText="Confirm Choice"
                buttonStyle={{ backgroundColor: "black", color: "white", opacity: option === undefined ? "0.18" : "0.88" }}
                // style={{ margin: '3% 0 3% 0' }}
                disabled={option === undefined}
                endButtonIcon={<Icon path={mdiArrowRight}
                    size={1.5}
                    color="white"
                />}
                onClick={() => { }}
            />
        </div>

    </div>
}

export default FlashpointOptions