import { mdiConsoleLine } from "@mdi/js";
import { arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc } from "firebase/firestore"
import { app } from "./firebaseConfig";

const db = getFirestore(app);

export const getUserById = async (userId) => {
    const labRef = collection(db, "users")
    const unitRef = doc(labRef, userId);
    const data = await getDoc(unitRef)
    return data.data()
}

export const getLabUnit = async () => {
    const q = query(collection(db, "labs"))
    const units = {}
    const unit = await getDocs(q)

    unit.forEach(async (doc) => {
        Object.assign(units, { [doc.id]: { "_id": doc.id, ...doc.data() } })
    })

    for (const doc of Object.values(units)) {
        const experiments = doc.experiments
        if (experiments)
            for (const experiment of Object.values(experiments)) {
                const d = await getUserById(experiment.sharedBy)
                Object.assign(experiment, { sharedBy: d })
            }
        Object.assign(units, { [doc._id]: { ...doc, experiments } })
        localStorage.setItem("lab_units", JSON.stringify(units))
    }

    return units
}

export const getUnit = (unitId) => {
    const units = JSON.parse(localStorage.getItem("lab_units"))
    return units?.[unitId]
}

export const shareExperiment = async (unitId, experiment) => {
    const labRef = collection(db, "labs")
    const unitRef = doc(labRef, unitId);
    await updateDoc(unitRef, { [`experiments.${experiment.id}`]: experiment })
}

export const appreciateExperiment = async (unitId, experimentId, experiment, userId, rating) => {

    Object.assign(experiment, { sharedBy: experiment.sharedBy.uid })
    const docRef = doc(db, "experiments_rating", experimentId + "_" + userId)
    const docSnap = await getDoc(docRef)
    const unitRef = doc(db, "labs", unitId)
    const unit = await getDoc(unitRef)

    let totalRating = parseInt(unit.data().experiments?.[experimentId]?.totalRating || 0);

    if (docSnap.exists()) {
        // Already Rated. Updating the rating
        totalRating = totalRating + parseInt(rating) - parseInt(docSnap.data().rating)
        await updateDoc(docRef, { rating, updatedAt: new Date() })
        updateDoc(unitRef, { [`experiments.${experimentId}.totalRating`]: totalRating })
    }
    else {
        // Giving rating for first time
        await setDoc(docRef, { rating: rating, ratingTime: new Date() })
        totalRating = totalRating + parseInt(rating)

        updateDoc(unitRef, { [`experiments.${experimentId}.totalRating`]: totalRating })
    }

    const units = JSON.parse(localStorage.getItem("lab_units"))

    localStorage.setItem("lab_units", JSON.stringify({
        ...units,
        [unitId]: {
            ...units?.[unitId],
            experiments: {
                ...units?.[unitId].experiments,
                [experimentId]: {
                    ...units?.[unitId].experiments?.[experimentId],
                    totalRating
                }
            }
        }
    }))
}