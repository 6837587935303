import { mdiArrowRight, mdiFaceMan } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom"
import { getFile } from '../../network/firebaseStorageAction';
import { Slideshow } from '../Slideshow';
import "./index.scss"

const LabCard = ({ unit, user }) => {
    const experiment = unit?.experiments && unit?.experiments[Object.keys(unit?.experiments)[0]]
    const experimentCount = unit?.experiments && Object.keys(unit.experiments).length
    const [images, setImages] = useState([])

    useEffect(() => {
        (async () => {
            const f = await getFile(experiment.img_urls?.[0])
            setImages(oldValue => [...oldValue, f])
        })()
    }, [experiment])

    return <div className="p-3">
        <div>
            <span style={{ color: "#999999" }}>Lab {unit?.number}</span>
            <br />
            <span className='font-20 line-26'>{unit?.title}</span>
        </div>
        <iframe
            width="100%"
            height="300"
            src={unit?.video_url}
            title={unit?.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen />
        {/* <Player
            poster="/assets/poster.png"
            src="https://youtu.be/uC6SssIoIn4">
            <BigPlayButton position="center" />
        </Player> */}
        {experiment && <>
            <div style={{ borderBottom: "1px solid black", textTransform: "uppercase", color: "#999999" }} className="mt-3">experiments</div>
            <div className='pt-2'>
                <img src={experiment?.sharedBy?.photoURL} className="avatar" style={{}} alt="user-avatar" referrerPolicy="no-referrer" />
                {/* <Icon path={mdiFaceMan} size={1} /> */}
                <span style={{ marginLeft: "5%" }}>{experiment?.sharedBy?.displayName}</span>
                {/* <span style={{ marginLeft: "5%" }}> */}
                {/* <span>&middot;</span> */}
                {/* <span style={{ color: "#939393" }}>{new Date(experiment?.sharedAt)}</span> */}
                {/* </span> */}
            </div>
            <div>{experiment?.description}</div>
            {/* <Slideshow /> */}
            {images?.[0] && <img src={images?.[0]} alt="experiment-img" />}
            {experimentCount > 0 && <div className='my-2 mb-4'>
                <Link to={`/lab/experiments/${unit?._id}`} style={{ color: "#BA1F00", textDecoration: "none" }}>view all {experimentCount} experiments <Icon path={mdiArrowRight} size={0.8} /> </Link>
            </div>}
        </>}
        <div className='d-flex' style={{ marginTop: experiment ? "none" : '3%' }}>
            <Link className="lab mx-auto d-flex" to={`/lab/share/${unit?._id}`}>
                <img src={user?.photoURL} className="avatar" alt="user-avatar" referrerPolicy="no-referrer" />
                {/* <Icon path={mdiFaceMan} size={1} /> */}
                <span style={{ marginLeft: "2%" }}>{unit?.shareButtonText}</span>
            </Link>
        </div>
    </div>
}

export default LabCard