import { addDoc, collection, doc, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore";
import { app } from "./firebaseConfig";

const db = getFirestore(app);

export const saveDocument = async (docLevel1, docLevel2, document) => {
    await setDoc(doc(db, docLevel1, docLevel2), document);
}

export const addDocument = async (docLevel1, document) => {
    await addDoc(collection(db, docLevel1), document)
}

export const getDocument = async (docLevel1, key = null, operator = null, value = null, order_by = null, order = null, limit = null) => {
    let q
    if (key)
        q = query(collection(db, docLevel1), where(key, operator, value))
    else
        q = query(collection(db, docLevel1))
    return await getDocs(q)
}

export const getDocumentRef = async (docLevel1, userId) => {
    return doc(db, docLevel1, userId)
}