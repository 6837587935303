import { collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { app } from "./firebaseConfig";
import moment from "moment"

const db = getFirestore(app);

export const getAllFlashpoints = async () => {
    const q = query(collection(db, "flashpoints"))
    const _flashpoints = []
    const flashpoints = await getDocs(q)

    flashpoints.forEach((doc) => {
        _flashpoints.push({ "_id": doc.id, ...doc.data() })
    })
    return _flashpoints
}

export const getTodaysFlashpoints = async () => {
    // const q = query(collection(db, "flashpoints"), where("assignedFor", "==", moment().startOf('day').add(1, "days").toDate()), limit(1))
    const currentTime = moment().utc().toDate()

    // assignedFor < currentTim < nextFlashpoint
    const q = query(
        collection(db, "flashpoints"),
        where("assignedFor", "<", currentTime),
        // where("nextFlashpoint", ">=", currentTime),
        orderBy("assignedFor", "desc"),
        // limit(1)
    )
    const _flashpoints = {}
    const flashpoints = await getDocs(q)

    flashpoints.forEach((doc) => {
        Object.assign(_flashpoints, {
            [doc.id]: {
                "_id": doc.id,
                ...doc.data()
            }
        })
    })

    for (const _flash of Object.values(_flashpoints)) {
        const status = await isFlashpointComplete(_flash._id)
        Object.assign(_flash, { isCompletedFlashpoint: status })
    }

    localStorage.setItem("flashpoints", JSON.stringify(_flashpoints))
    return _flashpoints
}

export const getFlashpointById = (id) => {
    const flashpoints = JSON.parse(localStorage.getItem("flashpoints"))
    // console.log(flashpoints)
    return flashpoints?.[id]
}

export const flashpointUserInteraction = async (path) => {
    const flash_id = path.split("/")[1]
    if (flash_id !== "") {
        const user = JSON.parse(localStorage.getItem("user"))
        const flashpoint = getFlashpointById(flash_id)

        const docRef = doc(db, "flashpoint_user", user.uid + "_" + flashpoint?._id)

        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) {
            await updateDoc(docRef, { [new Date().getTime()]: path })
        }
        else {
            await setDoc(docRef, { [new Date().getTime()]: path })
        }
    }
}

export const completeFlashpoint = async (score, id) => {
    const user = JSON.parse(localStorage.getItem("user"))
    // console.log(score, id)
    const flashpoint = getFlashpointById(id)

    const docRef = doc(db, "flashpoint_user", user.uid + "_" + flashpoint._id)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists() && docSnap.data().score) {
    }
    else {
        if (docSnap.exists())
            await updateDoc(docRef, { score })
        else
            await setDoc(docRef, { score })

        // Object.assign(flashpoint, { ...flashpoint, [flashpoint._id]: { ...flashpoint?.[flashpoint._id], isCompletedFlashpoint: true } })

        // localStorage.setItem("flashpoints", JSON.stringify(flashpoint))
    }
}

export const isFlashpointComplete = async (flashpointId) => {
    const user = JSON.parse(localStorage.getItem("user"))

    const docRef = doc(db, "flashpoint_user", user.uid + "_" + flashpointId)
    const docSnap = await getDoc(docRef)

    return docSnap.exists() && docSnap.data().score ? true : false
}