import PropTypes from 'prop-types';
import { LinkButton } from "../LinkButton";
import { mdiArrowRight, mdiCheckCircle } from '@mdi/js';
import Icon from '@mdi/react'
import { TopBar } from "../TopBar"

export const FlashpointCard = ({ title, cardImage, cardButtonText, redirectLink, fullScreen, cardColor, topBarText, completed, loading, setLoading, onLoad }) => {

    return <div style={{ backgroundColor: cardColor }}>
        {fullScreen && <TopBar title={topBarText} />}
        <div className="card flashpoint-card" style={{ paddingLeft: fullScreen ? '0' : '', paddingRight: fullScreen ? '0' : '' }}>
            {/* {!fullScreen && <div className="flashpoint-label">FLASHPOINT</div>} */}
            <div style={{ display: 'flex' }}>
                <span className="flashpoint-title" style={{ paddingRight: completed ? "5%" : "" }}>{title}</span>
                {completed && <span><Icon path={mdiCheckCircle} size={2} color="white" /></span>}
            </div>

            <img src={cardImage} className={`card-image m-auto my-4 ${fullScreen ? 'w-100 mt-5' : ''}`} alt="flashpoint-small-icon" onLoad={onLoad} />

            {/* {loading && <div className="spinner-border text-primary m-auto" role="status" />} */}

            <LinkButton to={redirectLink} buttonText={cardButtonText}
                endButtonIcon={<Icon path={mdiArrowRight}
                    size={1.5}
                // color="black"                    
                />}
                buttonStyle={{ marginLeft: fullScreen ? "22px" : '', marginRight: fullScreen ? "22px" : "" }}
            />
        </div>
    </div>
}


FlashpointCard.propTypes = {
    title: PropTypes.string,
    cardImage: PropTypes.string.isRequired,
    cardButtonText: PropTypes.string.isRequired,
    redirectLink: PropTypes.string.isRequired,
    cardColor: PropTypes.string.isRequired,
}