import React, { useEffect, useState } from 'react';
import { mdiArrowLeft, mdiFaceMan, mdiStar } from "@mdi/js"
import Icon from "@mdi/react"
// import { Button } from "bootstrap"
import { Link, useParams } from "react-router-dom"
import StarRating from "../../components/StarRating"
import "./index.scss"
import { appreciateExperiment, getLabUnit, getUnit } from '../../network/labAction';
import { getCurrentUser } from '../../network/authAction';
import { async } from '@firebase/util';
import { getFile } from '../../network/firebaseStorageAction';

const LabExperiment = () => {
    const [rating, setRating] = useState()
    const [unit, setUnit] = useState({})
    const { unitId } = useParams()
    const user = getCurrentUser()
    const [images, setImages] = useState({})
    const params = new URLSearchParams(window.location.search);
    const experimentId = params.get("experimentId");

    useEffect(() => {
        setUnit(getUnit(unitId))
    }, [rating])

    const handleRatingChange = (id, experimentId, experiment, rating) => {
        (async () => {
            await appreciateExperiment(unitId, experimentId, experiment, user.uid, rating)
            setRating(oldValue => !oldValue)
        })()
    }

    useEffect(() => {
        (async () => {
            if (experimentId) {
                await getLabUnit()
                setRating(oldValue => !oldValue)
            }
        })()
    }, [])


    // useEffect(() => {
    //     const div_to_scroll = document.getElementById(experimentId);
    //     window.scrollTo({
    //         top: div_to_scroll?.offsetTop,
    //         behavior: "smooth"
    //     });
    // }, [experimentId])

    useEffect(() => {
        (async () => {
            if (unit?.experiments) {
                for (const experiment of Object.values(unit?.experiments)) {
                    if (experiment.img_urls) {
                        const f = await getFile(experiment.img_urls?.[0])
                        setImages(oldValue => ({ ...oldValue, [experiment.id]: f }))
                    }
                }
            }
        })()
    }, [unit])

    useEffect(() => {

    }, [])

    return <>
        <div>
            <Link to="/" style={{ textDecoration: "none", color: "black" }}>
                <Icon path={mdiArrowLeft} size={1} />
            </Link>
            <span>Explore Experiments ({unit.experiments && Object.keys(unit.experiments)?.length || 0})</span>
        </div>

        <div className='experiments'>
            {unit?.experiments && Object.values(unit?.experiments)?.map((experiment, id) => <div className="form-group p-3 rounded border-bottom" style={{ borderColor: "#AFAFAF" }} key={id} id={experiment?.id}>
                <label htmlFor="experiment1" style={{ width: "100%" }}>
                    <img src={experiment?.sharedBy?.photoURL} className="avatar" style={{}} alt="user-avatar" referrerPolicy="no-referrer" />
                    <span style={{ marginLeft: "5%" }}>{experiment?.sharedBy?.displayName}</span>
                    {/* <span style={{ marginLeft: "5%" }}>&middot;</span> */}
                    {/* <span>1h</span> */}
                </label>
                <div>{experiment?.description}</div>
                <img src={images?.[experiment?.id]} />

                {experiment?.totalRating > 0 && <div className="border rounded-pill p-2 mt-3" style={{ width: "fit-content" }}>
                    {experiment?.totalRating}
                    <Icon path={mdiStar} size={0.8} />
                </div>}

                <div>
                    <StarRating numberOfStars={5} currentRating={0}
                        // extra={
                        //     // rating?.[experiment?.id] > 0 && 
                        //     <span style={{ marginLeft: "2%" }}>Appreciate</span>
                        // }
                        onClick={(rating) => handleRatingChange(id, experiment?.id, experiment, rating)} />
                </div>
            </div>)}


            <Link className="lab fixed-bottom m-2 d-flex" to={`/lab/share/${unitId}`} style={{ width: "inherit" }}>
                {/* <Icon path={mdiFaceMan} size={1} /> */}
                <img src={user?.photoURL} className="avatar" style={{}} alt="user-avatar" referrerPolicy="no-referrer" />
                <span style={{ marginLeft: "2%" }}>{unit?.shareButtonText}</span>
            </Link>
        </div>
    </>
}

export default LabExperiment