import { mdiArrowLeft, mdiCamera } from "@mdi/js"
import Icon from "@mdi/react"
import { useEffect, useState } from "react"
import { Button, Spinner } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import Lab from "../../assets/lab-icon.svg"
import { getCurrentUser } from "../../network/authAction"
import { uploadFile } from "../../network/firebaseStorageAction"
import { getUnit, shareExperiment } from "../../network/labAction"
import { v4 as uuidv4 } from 'uuid';
import sanitizeHtml from 'sanitize-html';
// import { uploadFile } from "../../network/firebaseStorageAction"

const ShareExperience = () => {
    const [experiment, setExperiment] = useState()
    const [images, setImages] = useState([])
    const user = getCurrentUser()
    const [percent, setPercent] = useState(0)
    const [files, setFiles] = useState([])
    const [_experimentId, setExperimentId] = useState()

    const handleChanger = (event) => {
        setFiles(event.target.files)
    }

    const { unitId } = useParams()
    const unit = getUnit(unitId)

    useEffect(() => {
        if (files.length > 0)
            for (const file of files) {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);
                fileReader.addEventListener("load", function () {
                    setImages(oldValue => [...oldValue, this.result])
                });
            }
    }, [files])

    const shareHandler = () => {
        const experimentId = uuidv4()
        const obj = {
            sharedBy: user.uid,
            // sharedAt: new Date(),
            description: experiment,
            id: experimentId
        }

        if (files?.[0]) {
            Object.assign(obj, { img_urls: [`/${unitId}/${files[0].name}`] })
            const uploadTask = uploadFile(unitId, files[0])

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    setPercent(percent);
                },
                (err) => console.log(err),
                () => {
                }
            );
        }
        else {
            setPercent(100)
        }

        (async () => {
            await shareExperiment(unitId, obj)
            setExperimentId(experimentId)
        })()
    }

    useEffect(() => {
        if (percent == 100) {
            window.location.href = `/lab/experiments/${unitId}?experimentId=${_experimentId}`
        }
    }, [percent])

    return <>
        <div>
            <Link to="/" style={{ textDecoration: "none", color: "black" }}> <Icon path={mdiArrowLeft} size={1} /></Link>
            <span>My Experiment</span>
        </div>

        <div className="form-group m-2 p-2 border rounded" style={{ borderColor: "#AFAFAF" }}>
            <div htmlFor="experiment">
                <img src={user?.photoURL} className="avatar" alt="user-avatar" referrerPolicy="no-referrer" />
                <span style={{ marginLeft: "3%" }}>{user?.displayName}</span>
            </div>
            <textarea
                className="lab form-control"
                id="experiment"
                rows="5"
                placeholder="Share the story of your experiment ..."
                value={experiment}
                onChange={(event) => { setExperiment(event.target.value) }}
                style={{ marginTop: "3%" }} />
            {/* <label for="myfile">Select a file:</label> */}
            {images.map((img, index) => <img key={index} alt="img" src={img} style={{ maxWidth: "100%" }} />)}
            <label htmlFor="attach-images" className="btn w-100" style={{ backgroundColor: "#FFFFFF", color: "#858585", border: '1px solid #CACACA', marginTop: "3%" }} disabled={experiment ? false : true}>
                <Icon path={mdiCamera} size={1} />
                <span style={{ marginLeft: "2%" }}>Add photo(s)</span>
            </label>
            <input type="file" id="attach-images" name="attach-images" hidden accept="image/*" onChange={handleChanger} />
            <Button className="lab" style={{ marginTop: "3%" }} disabled={experiment && (percent == 0 || percent == 100) ? false : true} onClick={shareHandler}>
                {percent > 0 && percent < 100 ? <Spinner animation="border" /> : <span style={{ marginLeft: "2%" }}>Share</span>}
            </Button>
        </div>

        <div className="m-4">
            <div className="d-flex col">
                <img src={Lab} alt="lab-icon" />
                <span>Experiment <br />Triggers</span>
            </div>
            <div className="mt-3">
                <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(unit?.trigger) }} />
                {/* <span>You can share an experiment where…
                    <ul>
                        <li> You were scared and you yet tried to face your fear</li>
                        <li>You admitted your mistake and tried to change</li>
                        <li>You were given a tough responsibility and took it up bravely</li>
                        <li>Choose to do something that you thought was right to do even if others did not do it</li>
                        <li>and more….</li>
                    </ul>
                </span> */}
            </div>
        </div>
    </>
}

export default ShareExperience