import growOn1 from "../../assets/mindgog-nav-logo.svg"
import "./index.scss"
import { useEffect, useState } from "react"
import { SplashScreen } from "../SplashScreen"
import { Tabs, Tab } from "react-bootstrap"
import HomeFlashpoint from "./flashpoint"
import HomeLab from "./lab"

const HomeComponent = () => {

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState()

    useEffect(() => {
        setCompleted(loading === 0)
    }, [loading])

    return <div className="d-flex row">

        <div style={{ display: !completed ? "none" : 'flex', alignItems: 'center', justifyContent: 'space-between' }} className="p-4">
            <img src={growOn1} alt="logo" style={{ width: "45%  " }} onLoad={() => { setLoading((oldValue => oldValue - 1)) }} />
        </div>

        <SplashScreen text={"Flashpoint"} hidden={completed} />

        <Tabs defaultActiveKey="lab" id="uncontrolled-tab-example" className="" hidden={!completed}>
            <Tab eventKey="flashpoint" title="Flashpoint">
                <HomeFlashpoint completed={completed} loading={loading} setLoading={setLoading} />
            </Tab>
            <Tab eventKey="lab" title="Lab">
                <HomeLab />
            </Tab>
        </Tabs>
    </div >
}

export default HomeComponent