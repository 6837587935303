import {
    //  mdiClose,
    mdiHome
} from '@mdi/js'
import Icon from '@mdi/react'
import React from 'react'
import { Link } from 'react-router-dom'
import mindGogNavLogo from "../../assets/mindgog-nav-logo.svg"

export const TopBar = ({ title, hidden }) => {
    return <Link to="/" className="top-bar fixed-top" style={{ display: hidden ? 'none' : "flex" }}>
        {/* <div > */}
        <img src={mindGogNavLogo} style={{ width: "35%" }} alt="mindgog-logo" />
        {/* <Link to={"/"}> */}
        <Icon path={mdiHome} size={1.3} color="black" />
        {/* </Link> */}
        {/* </div> */}
    </Link>
}