import growOn2 from "../../assets/mindgog-home-logo.svg"
// import illumineLogo from "../../assets/illumine-logo.svg"
import { loginWithGoogle } from "../../network/authAction"
import googleIcon from "../../assets/GoogleIcon.svg"
// import Blob from "../../assets/blob.svg"

const Login = () => {
    // if (!currentUser)
    return <div className="d-flex m-auto" style={{
        // width: '100%', 
        height: '100vh',
        paddingTop: "35%",
        // justifyContent: "center", 
        flexDirection: "column"
    }}>
        <div className="d-flex mx-auto" style={{ flexDirection: "column", width: "80%"}}>
            {/* <div style={{ width: "fit-content", marginLeft: "auto", marginRight: 'auto', display: "flex", alignItems: "center" }}>
                <img src={illumineLogo} style={{ width: "40px" }} className="m-auto" alt="illumine-icon" />
                <span className="w-fit" style={{ fontSize: "18px", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>Illumine presents</span>
            </div> */}
            <img src={growOn2} style={{ width: '60%', marginLeft: "auto", marginRight: "auto", }} alt="growon-icon" />
        </div>
        <div style={{ color: 'black', border: '3px solid black', fontWeight: 'bold', padding: "6% 5% 6% 10%", fontSize: "22px", borderRadius: "30px", margin: "5%", marginBottom: "10%" }} className="btn mt-5 fixed-bottom" alt="" onClick={loginWithGoogle}>
            <img src={googleIcon} width="30px" alt="google-icon" />
            <span style={{ marginLeft: "5%" }}> Login with Google</span>
        </div>
        {/* <div className="fixed-bottom m-auto d-flex row w-fit" style={{ flexDirection: "column" }}>
            </div> */}
    </div >
}

export default Login