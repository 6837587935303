
import { app } from "./firebaseConfig";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";


const storage = getStorage(app);

export const uploadFile = (unitId, file) => {
    const storageRef = ref(storage, `/${unitId}/${file.name}`)
    // const storageRef = ref(storage, `/${file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file);
    return uploadTask
}

export const getFile = async (storagePath) => {
    let _url
    const storageRef = ref(storage, storagePath)
    // console.log(storagePath, storagePath.split("/"))
    await getDownloadURL(storageRef).then((url) => {
        _url = url
    }).catch(error => console.error(error));
    return _url
}