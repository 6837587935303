import Lab from "../../assets/Vector.svg"
import LabCard from "../../components/LabCard"
import Star from "../../assets/Star.svg"
import { useEffect, useState } from "react"
import { getLabUnit } from "../../network/labAction"
import { getCurrentUser } from "../../network/authAction"

const HomeLab = () => {
    const [myScore,] = useState(0)
    const [labUnit, setLabUnit] = useState({})

    useEffect(() => {
        (async () => {
            const lab = await getLabUnit()            
            setLabUnit(lab)
        })()
    }, [])

    return <>
        <div className="p-3" style={{ background: "linear-gradient(180deg, #FFA336 0%, #FF7E36 100%)" }}>
            <div className="d-flex col" style={{ color: "white" }}>
                <img src={Lab} alt="lab-icon" />
                <div className="mx-2" style={{ height: "fit-content", alignSelf: "end" }}>
                    <div>Welcome to the</div>
                    <div>mindgog lab</div>
                </div>
            </div>
            <div className="mt-3" >
                <b>What you can do in this lab:</b>
                <div style={{ display: "table" }}>
                    <ul style={{ paddingLeft: "1rem" }}>
                        <li>First of all, experiment with your growth possibility in your own life</li>
                        <li >Second, share your experiments with others!</li>
                        <li >Appreciate valuable contributions of your peers</li>
                    </ul>
                </div>
            </div>
            {myScore > 0 && <div style={{ background: "rgba(255, 255, 255, 0.94)", "border-radius": "23px", color: "black", border: "none" }} className="w-100 btn">
                Your Lab Score <br />
                <div style={{ justifyContent: "center", display: "flex" }}>
                    <span style={{ height: "fit-content", alignSelf: "end", fontWeight: "700", fontSize: "36px", lineHeight: "22px" }}>{myScore}</span>
                    <img src={Star} alt="star" />
                </div>
            </div>}
        </div >

        {labUnit && Object.values(labUnit)?.map(unit => <LabCard
            unit={unit}
            user={getCurrentUser()} />)}
    </>
}

export default HomeLab