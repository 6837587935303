import { useParams } from "react-router-dom"
// import { flashpoints } from "../../assets/flashpoint"
import { LinkButton } from "../../components/LinkButton"
import bulb5 from "../../assets/bulbs-gif-v3.gif"
import bulb3 from "../../assets/3-star-bulbs-gif-v3.gif"
import bulb1 from "../../assets/bulb-single.svg"
import { mdiArrowRight } from '@mdi/js';
import Icon from '@mdi/react'
import { TopBar } from "../../components/TopBar"
import { completeFlashpoint, getFlashpointById } from "../../network/flashpointAction";
import { useEffect } from "react";
// import { CelebrationAnimation } from "../../components/CelebrationAnimation"

const OptionScoreText = ({ score }) => {
    let text, imgSrc;

    if (score === 5) {
        text = "WOW!"
        imgSrc = bulb5
    }
    else if (score === 3) {
        text = "Great!"
        imgSrc = bulb3
    }
    else {
        text = ""
        imgSrc = bulb1
    }

    return <div style={{ marginTop: "30%" }}>
        <img src={imgSrc} className="mx-auto" style={{ maxWidth: "100%", display: "table-cell" }} alt="score-icon"/>
        <div className=" flashpoint-title mb-4" style={{ color: "black", textAlign: "center", paddingRight: "0" }}>
            {text}
            <br />
            You Scored {score} point{score > 1 ? "s" : ""}
        </div>
    </div>
}

const OptionScore = () => {
    const { flashpointId, optionId } = useParams()

    const flashpoint = getFlashpointById(flashpointId)

    const option = flashpoint?.options.filter(opt => opt.opt_id === optionId)[0]

    useEffect(() => {
        completeFlashpoint(option?.opt_score, flashpoint?._id)
    })

    return <div style={{
        backgroundColor: flashpoint.color,
        // backgroundColor: "#0C6FF9",                
        height: "100vh",
        overflowY: "hidden",        
    }}>
        {/* <CelebrationAnimation /> */}
        <TopBar title={flashpoint.topBarText} />
        <div className="flashpoint score" style={{ marginTop: "10%" }}>
            <OptionScoreText score={option.opt_score} />

            {/* <div style={{
                    backgroundImage: ` url(${score_bckgrnd})`, height: "45vh", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', marginTop: "auto", display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center"
                }}>
                    <span className="score-point">{option.opt_score}</span>
                    <span>points</span>
                </div> */}

            <LinkButton
                to={"reason"}
                buttonText={flashpoint.option_next_button_text}
                endButtonIcon={<Icon path={mdiArrowRight}
                    size={1.5}
                    color="black"
                />}
                buttonStyle={{ backgroundColor: 'white', color: 'black', width: "90%", marginLeft: "auto", marginRight: "auto", marginBottom: "7%" }}
                buttonClasses="fixed-bottom"
            />

        </div>
    </div >
}

export default OptionScore