import React, { useEffect, useState } from "react"
import {
    // useLocation, 
    useParams
} from "react-router-dom"
// import { flashpoints } from "../../assets/flashpoint"
import { FlashpointCard } from "../../components/FlashpointCard"
import { getFlashpointById } from "../../network/flashpointAction"
import { SplashScreen } from "../SplashScreen"

const FlashpointHomePage = () => {
    const { flashpointId } = useParams()
    const [flashpoint, setFlashpoint] = useState()
    const [loading, setLoading] = useState(true)

    // const location = useLocation()
    // const completed = flashpoint?.isCompletedFlashpoint

    useEffect(() => {
        (async () => {
            const flash = await getFlashpointById(flashpointId)
            setFlashpoint(flash)
        })()
    }, [flashpointId])

    return <>
        {loading && <SplashScreen text={"Flashpoint"} />}
        <FlashpointCard
            topBarText={flashpoint?.topBarText}
            cardId={flashpoint?._id}
            cardImage={flashpoint?.fullImg || ''}
            cardButtonText={flashpoint?.cardButtonText2 || ''}
            cardColor={flashpoint?.color || ''}
            redirectLink={'options'}
            // redirectLink={completed ? `options/${undefined}/reason` : 'options'}
            fullScreen={true}
            setLoading={setLoading}
            loading={loading}
            onLoad={() => { setLoading(false) }}
        />
    </>
}

export default FlashpointHomePage